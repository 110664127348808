<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect" default-expand-all row-key="id" grouped="true" border :span-method="objectSpanMethod">
		<el-table-column type="selection" width="55">
		</el-table-column>
		<el-table-column label="站点" prop="channel" min-width="120">
		</el-table-column>
		<el-table-column label="品类缩写" prop="categoryCode" min-width="120">
		</el-table-column>
		<el-table-column label="发布类型" prop="publishType" min-width="100">
		</el-table-column>
		<el-table-column label="品类" prop="category" min-width="180">
		</el-table-column>
		<el-table-column label="Landing Link" prop="landingLink" min-width="250">
		</el-table-column>
		<el-table-column label="发布状态" min-width="150">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.active" @change="$emit('changePublish', scope.row)"></el-switch>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="100">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		},
		methods: {
			objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			    if (columnIndex <= 3 || columnIndex === 7) {
					const details = row.details || []
					const rowspan = Array.isArray(row.details) ? Math.max(details.length, 1) : 0
			        return {
			        	rowspan,
			        	colspan: 1
			        }
				}
			}
		}
	}
</script>

<style>
</style>

<template>
	<el-card>
		<div class="d-flex flex-row flex-wrap">
			<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false"
				@onload="q.channel=queryForm.channel;getList()" :clearable="false"></form-item-sites>
			<form-item label="品类缩写:" style="width:200px;">
				<el-input v-model.trim="queryForm.categoryCode"
					@input="queryForm.categoryCode=queryForm.categoryCode.toUpperCase()" placeholder="请输入" size="mini">
				</el-input>
			</form-item>
			<form-item-publishtypes v-model="queryForm.publishType" clearable platform="gg"></form-item-publishtypes>
			<form-item label="发布状态:" style="width:230px;">
				<el-select v-model="queryForm.active" size="mini" clearable>
					<el-option label="开启" :value="true"></el-option>
					<el-option label="暂停" :value="false"></el-option>
				</el-select>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" @click="visible.add=true">添加配置</el-button>
			</form-item>
		</div>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<alert v-if="multipleSelection.length > 0">
			已选择 <span class="text-primary">{{multipleSelection.length}}</span> 个。
			<el-dropdown @command="handleCommand">
				<span class="text-primary">
					批量操作
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="active">发布</el-dropdown-item>
					<el-dropdown-item command="deactive">取消发布</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</alert>
		<gg-category-url-table class="mt-3 w-100" :tableData="tableData" @handleSelectionChange="handleSelectionChange"
			@changePublish="changeActive" @editRow="editRow"></gg-category-url-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" width="80%" style="min-width:900px;">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true">
				<el-row :gutter="10">
					<el-col :span="8">
						<el-form-item-sites :abbr="true" v-model="form.channel" label-width="50px" prop="channel">
						</el-form-item-sites>
					</el-col>
					<el-col :span="8">
						<el-form-item label="品类缩写:" prop="categoryCode" label-width="80px" class="w-100">
							<el-input v-model.trim="form.categoryCode" placeholder="请输入品类缩写"
								@input="form.categoryCode=form.categoryCode.toUpperCase()"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="发布类型:" prop="publishType" label-width="80px">
							<form-item-publishtypes :with-layout="false" v-model="form.publishType" platform="gg">
							</form-item-publishtypes>
						</el-form-item>
					</el-col>
				</el-row>
				<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
				<div class="d-flex flex-row align-items-center mb-2">
					<h5 class="mb-0 mr-2">品类&落地页对应规则</h5>
					<el-button style="border: 1px dashed;" size="mini"
						@click="form.detail.push({category: '', landingLink: '', active: true})">+添加规则</el-button>
				</div>
				<el-row :gutter="20" v-for="(detail, index) in form.detail">
					<el-col :span="10">
						<el-form-item label="品类全称:" label-width="80px" :prop="`detail.${index}.category`"
							:rules="{required:true,message:'品类全称不能为空'}">
							<el-input v-model="detail.category" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="落地页链接:" label-width="100px" :prop="`detail.${index}.landingLink`"
							:rules="{required:true,message:'落地页链接不能为空'}">
							<el-input v-model="detail.landingLink" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<div class="d-flex flex-row align-items-center justify-content-between"
							style="max-width:100px;height:40px;">
							<el-switch v-if="form.id" v-model="detail.active"></el-switch>
							<el-button v-if="index > 0" type="text" class="text-danger"
								@click="form.detail.splice(index, 1)">删除</el-button>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapState,
		mapGetters
	} from 'vuex'
	import GgCategoryUrlTable from '@/components/google-contents/gg-category-url-table.vue'
	import config from './minixs/index.js'
	import Lodash from 'lodash'
	const InitialDetail = {
		category: '',
		landingLink: '',
		active: true
	}
	const InitialForm = {
		channel: '',
		categoryCode: '',
		publishType: 'UDA'
	}
	export default {
		mixins: [config],
		components: {
			GgCategoryUrlTable
		},
		data() {
			return {
				queryForm: {
					channel: '',
					categoryCode: '',
					publishType: '',
					active: ''
				},
				tableData: [],
				q: {
					channel: ''
				},
				visible: {
					add: false
				},
				form: {
					...InitialForm,
					detail: [{
						...InitialDetail
					}]
				},
				rules: {
					channel: [{
						required: true,
						message: '站点不能为空'
					}],
					categoryCode: [{
						required: true,
						message: '品类缩写不能为空'
					}],
					publishType: [{
						required: true,
						message: '发布类型不能为空'
					}]
				},
				fetchOnCreated: false,
				namespace: 'google-contents-landinglink'
			}
		},
		methods: {
			getId(item) {
				return item.groupId
			},
			fillForm(data) {
				const {
					details,
					category
				} = data
				this.form = {
					...data,
					detail: details,
					categoryCode: category || ''
				}
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = {
						...InitialForm,
						detail: [{
							...InitialDetail
						}]
					}
				}
			}
		}
	}
</script>

<style>
</style>
